import React from 'react';
import { Button } from "primereact/button";

const ExportPdfContacts = ({ filteredContactData }) => {

	const exportColumnsContacts = [
		{ title: "First Name", dataKey: "firstName" },
		{ title: "Last Name", dataKey: "lastName" },
		{ title: "Department", dataKey: "businessUnit" },
		{ title: "Landline", dataKey: "workPhone" },
		{ title: "Mobile", dataKey: "workMobliePhone" },
	];

	const formatDate = (value) => {
		return value.toLocaleDateString("en-US", {
			day: "2-digit",
			month: "2-digit",
			year: "2-digit",
		});
	};

	const exportPdfContacts = () => {
		filteredContactData.forEach((emp) => {
			emp.imageData = "data:application/pdf;base64," + emp.thumbnail;
			emp.hireDate = formatDate(new Date(emp.hireDate));
		});
		import("jspdf").then((jsPDF) => {
			import("jspdf-autotable").then(() => {
				const doc = new jsPDF.default("p", "pt", "a4");
				let i = 0;
				doc.autoTable(exportColumnsContacts, filteredContactData, {
					styles: { fontSize: 10 },
					rowPageBreak: "avoid",
				});
				doc.save("rolodexContactsExport.pdf");
			});
		});
	};

	return (
		<Button label="Export PDF"
			aria-label="Export PDF"
			onClick={() => exportPdfContacts()} />
	);
}

export default ExportPdfContacts;