import React, { createRef, forwardRef, useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { classNames } from 'primereact/utils';
import { Ripple } from 'primereact/ripple';

const AppSubmenu = forwardRef((props, ref) => {
	const [activeIndex, setActiveIndex] = useState(null);

	const onMenuItemClick = (event, item, index) => {
		if (item.disabled) {
			event.preventDefault();
			return;
		}

		//execute command
		if (item.command) {
			item.command({ originalEvent: event, item });
			event.preventDefault();
		}

		if (item.items) {
			setActiveIndex(activeIndex === index ? null : index);
			event.preventDefault();
		}
		else {
			if (props.menuMode !== 'static') {
				const ink = getInk(event.currentTarget);
				if (ink) {
					removeClass(ink, 'p-ink-active');
				}
			}
		}

		if (props.root) {
			props.onRootMenuitemClick({
				originalEvent: event
			});
		}

		props.onMenuitemClick({
			originalEvent: event,
			item
		});
	};

	const getInk = (el) => {
		for (let i = 0; i < el.children.length; i++) {
			if (typeof el.children[i].className === 'string' && el.children[i].className.indexOf('p-ink') !== -1) {
				return el.children[i];
			}
		}
		return null;
	};

	const removeClass = (element, className) => {
		if (element.classList) element.classList.remove(className);
		else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
	};

	const isMobile = useCallback(() => {
		return window.innerWidth <= 1025;
	}, []);

	const onMenuItemMouseEnter = (index) => {
		if (props.root && props.menuActive && (isHorizontal() || isSlim()) && !isMobile()) {
			setActiveIndex(index);
		}
	};

	const visible = (item) => {
		return typeof item.visible === 'function' ? item.visible() : item.visible !== false;
	};

	const isSlim = useCallback(() => {
		return props.menuMode === 'slim';
	}, [props.menuMode]);

	const isHorizontal = useCallback(() => {
		return props.menuMode === 'horizontal';
	}, [props.menuMode]);

	const getLink = (item, index) => {
		const menuitemIconClassName = classNames('layout-menuitem-icon', item.icon);
		const content = (
			<>
				<i className={menuitemIconClassName}></i>
				<span className="layout-menuitem-text">{item.label}</span>
				{item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>}
				{item.badge && <span className="menuitem-badge">{item.badge}</span>}
				<Ripple />
			</>
		);
		const commonLinkProps = {
			style: item.style,
			className: classNames(item.className, 'p-ripple', { 'p-disabled': item.disabled, 'p-link': !item.to }),
			target: item.target,
			onClick: (e) => onMenuItemClick(e, item, index),
			onMouseEnter: () => onMenuItemMouseEnter(index)
		};

		if (item.url) {
			return (
				<a href={item.url}
					rel="noopener noreferrer"
					{...commonLinkProps}>
					{content}
				</a>
			);
		}
		else if (!item.to) {
			return (
				<button type="button" {...commonLinkProps}>
					{content}
				</button>
			);
		}

		return (
			<NavLink to={item.to}
				{...commonLinkProps}
				className={({ isActive }) => classNames(commonLinkProps.className, isActive ? 'router-link-active' : undefined)}>
				{content}
			</NavLink>
		);
	};

	const isMenuActive = (index) => {
		return activeIndex === index;
	};

	const getItems = () => {
		const transitionTimeout = { enter: 1000, exit: 450 };
		return props.items.map((item, i) => {
			if (visible(item)) {
				if (!item.separator) {
					const submenuRef = createRef();
					const menuitemClassName = classNames({ 'active-menuitem': activeIndex === i && !item.disabled }, { 'active-menuitem-notrouter': activeIndex === i && !item.items }, { 'layout-root-menuitem': props.root });
					const arrow = item.items && props.root && <div className="arrow"></div>;
					const link = getLink(item, i);
					const rootLabel = props.root && (
						<div className="layout-menuitem-root-text" style={{ textTransform: 'uppercase' }}>
							{item.label}
						</div>
					);
					const tooltip = props.root && (
						<div className="layout-menu-tooltip">
							<div className="layout-menu-tooltip-arrow"></div>
							<div className="layout-menu-tooltip-text">{item.label}</div>
						</div>
					);

					return (
						<li key={item.label || i}
							className={menuitemClassName}
							role="menuitem">
							{arrow}
							{link}
							{tooltip}
							{rootLabel}
							<CSSTransition
								nodeRef={submenuRef}
								classNames="layout-submenu-container"
								timeout={transitionTimeout}
								in={item.items && (props.root && !((isHorizontal() || isSlim()) && !isMobile && (!isSlim() || (isSlim() && activeIndex !== null))) ? true : isMenuActive(i))}
								unmountOnExit
							>
								<AppSubmenu ref={submenuRef}
									items={visible(item) && item.items}
									menuActive={props.menuActive}
									menuMode={props.menuMode}
									onMenuitemClick={props.onMenuitemClick}>
								</AppSubmenu>
							</CSSTransition>
						</li>
					);
				}
				else {
					return (
						<li className="menu-separator"
							style={item.style}
							key={`separator${i}`}
							role="separator">
						</li>
					);
				}
			}

			return null;
		});
	};

	useEffect(() => {
		if (!props.menuActive && (isSlim() || isHorizontal()) && !isMobile()) {
			setActiveIndex(null);
		}
	}, [props, isSlim, isHorizontal, isMobile]);

	if (!props.items) {
		return null;
	}

	const items = getItems();
	return (
		<ul ref={ref}
			className={props.className}
			role="menu">
			{items}
		</ul>
	);
});

export default AppSubmenu;