import React, { useEffect, useState, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import ExportPdfBirthday from "./ExportComponents/ExportPdfBirthday.js"
import ExportExcelBirthday from "./ExportComponents/ExportExcelBirthday.js"

const BirthdayTable = ({ employeeList, filters }) => {
	const [filteredBdayData, setFilteredBdayData] = useState([]);
	const [bdayEmpList, setBdayEmpList] = useState([]);
	const [departmentsBday, setDepartmentsBday] = useState(null);

	useEffect(() => {
		let now = new Date();
		let plus30 = new Date();
		plus30.setDate(plus30.getDate() + 30);
		let bdayList = [];
		let bdayDepts = [];

		for (let emp of employeeList) {
			let empBday = new Date(emp.birthDaySanitizedDate);
			if (empBday > now && empBday < plus30) {
				bdayList.push(emp);
				if (emp.businessUnit != null && !bdayDepts.includes(emp.businessUnit)) {
					bdayDepts.push(emp.businessUnit);
				}
			}
		}
		setBdayEmpList(bdayList);
		setFilteredBdayData(bdayList);
		setDepartmentsBday(bdayDepts.sort((a, b) => a.localeCompare(b)));

	}, [employeeList]);

	const filterFiltersBday = (filteredData) => {
		let filteredDepartments = [];
		filteredData.forEach((e) => {
			if (!filteredDepartments.includes(e.businessUnit) && e.businessUnit != null) {
				filteredDepartments.push(e.businessUnit);
			}
		});
		setDepartmentsBday(filteredDepartments.sort((a, b) => a.localeCompare(b)));
	};

	const departmentRowFilterTemplateBday = (options) => {
		return (
			<MultiSelect value={options.value}
				options={departmentsBday}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				placeholder="Select Departments"
				className="p-column-filter"
				showClear />
		);
	};

	const birthdayFilterTemplate = (options) => {
		const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	}

	return (
		<div style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
			<div>
				<DataTable
					scrollable
					size="small"
					scrollHeight="flex"
					style={{ fontSize: "18px" }}
					filters={filters}
					globalFilterFields={["lastName", "firstName", "employeeBirthday", "businessUnit"]}
					value={bdayEmpList}
					paginator
					filterDisplay="menu"
					className="p-datatable-customers"
					rows={10}
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
					rowsPerPageOptions={[10, 20, 50]}
					onValueChange={(bdayData) => {
						setFilteredBdayData(bdayData);
						filterFiltersBday(bdayData);
					}}
					sortField="birthDaySanitized"
					sortOrder={1}
				>
					<Column sortable
						filter
						align="center"
						alignHeader="center"
						header="First Name"
						field="firstName" />
					<Column sortable
						filter
						align="center"
						alignHeader="center"
						header="Last Name"
						field="lastName" />
					<Column sortable
						filter
						align="center"
						alignHeader="center"
						header="Department"
						filterElement={departmentRowFilterTemplateBday}
						field="businessUnit"
						showFilterMatchModes={false} />
					<Column sortable
						align="center"
						alignHeader="center"
						header="Birthday"
						filterElement={birthdayFilterTemplate}
						showFilterMatchModes={false}
						field="birthDaySanitized" />
				</DataTable>
			</div>
			<div>
				<ExportPdfBirthday filteredData={filteredBdayData} />
				<ExportExcelBirthday filteredData={filteredBdayData} />
			</div>
		</div>
	);
};

export default BirthdayTable;