import React from 'react';
import { Button } from "primereact/button";
import { saveAsExcelFile } from "./ExportFunctions";

// TODO: Update this component to use "ExportAsExcel" component

const ExportExcelContacts = ({ exportList }) => {

	const exportExcelContacts = () => {
		let excelExpexortList = [...exportList];
		let newList = [];
		for (let person of excelExpexortList) {
			newList.push(JSON.parse(JSON.stringify(person, ["firstName", "lastName", "businessUnit", "workPhone", "workMobliePhone"])));
		}
		
		import("xlsx").then((xlsx) => {
			const worksheet = xlsx.utils.json_to_sheet(newList);
			const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
			const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
			saveAsExcelFile(excelBuffer, "rolodexContactsExport");
		});
	};

	return (
		<Button style={{ float: "right" }}
			label="Export Excel"
			aria-label="Export Excel"
			onClick={() => exportExcelContacts()} />
	);
}

export default ExportExcelContacts;