import React, { useEffect, useState, useRef, useContext } from "react";
import MissingBadgeNumberTable from "./Tables/MissingBadgeNumberTable";
import MissingNameTable from "./Tables/MissingNameTable";
import { ProgressSpinner } from "primereact/progressspinner";
import { Message } from 'primereact/message';


export const Diagnostics = ({ originalList }) => {
    const [employeeList, setEmployeeList] = useState([]);
    const [lastUpdated, setLastUpdated] = useState("Cannot Determine");

    const content = (
        <div className="flex align-items-center">
            <i className="pi pi-info-circle" style={{ fontSize: '2rem' }}></i>
            <div className="ml-2" style={{
                fontWeight: 'bold',
                fontSize: '1.25rem'
            }}>{"Data last updated " + lastUpdated + " ago."}</div>
            {/*<div className="ml-2" style={{*/}
            {/*    fontSize: '.8rem'*/}
            {/*}}>{"Refresh the page for updates."}</div>*/}
        </div>
    );

    //console.log(lastUpdated);
    useEffect(() => {
        if (originalList !== undefined) {
            setEmployeeList(originalList);
            findSetMinimum(originalList);
        }
    }, [originalList]);

    const findSetMinimum = (list) => {
        const minTimestamp = list.reduce((min, current) => {
            return current.timestamp > min ? current.timestamp : min;
        }, list[0].timestamp);
        var minago = diffTimeAgo(minTimestamp);
        //console.log(minago);
        setLastUpdated(minago);
    }

    const diffTimeAgo = (feature) => {
        let edt = new Date(feature);
        let now = new Date();
        let dMs = now - edt;

        const minsDiff = Math.floor(dMs / (60 * 1000)) % 60;
        const hrsDiff = Math.floor(dMs / (60 * 60 * 1000));

        let toReturn = "";
        if (hrsDiff > 0) {
            toReturn += `${hrsDiff} hr`;
            toReturn += hrsDiff > 1 ? "s " : " ";
        }
        toReturn += `${minsDiff} min`;
        if (minsDiff > 1) {
            toReturn += "s";
        }

        return toReturn;
    }

    if (employeeList !== null && employeeList !== undefined && employeeList.length > 0) {
        return (

            <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
                <h1>Diagnostics</h1>
                <h5>This diagnostics page is to be used to identify issues when trying to sync ADP with CCURE. If an
                employee is listed on this page their picture will not show up in the Rolodex.</h5>
                <Message

                    className="card flex justify-content-center"
                    content={content}
                />

                <MissingBadgeNumberTable originalList={employeeList} />
                <MissingNameTable originalList={employeeList} />
            </div>

        );
    } else {
        return <ProgressSpinner style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "50%" }} />;
    }
};

