import React, { useEffect, useState, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ExportAsExcel from "../ExportComponents/ExportAsExcel";
import { FilterMatchMode, FilterOperator } from "primereact/api";

const MissingNameTable = ({ originalList }) => {
    const [missingName, setMissingName] = useState([]);

    const [filters, setFilters] = useState(null);

    var excelHeaders = ["firstName", "lastName", "department", "title", "badgeNumber"];
    var fileName = "MissingName";

    useEffect(() => {
        console.log(originalList);
        if (originalList !== undefined) {
            setMissingName(originalList.filter(employee => employee.ccureFirstName === null && employee.badgeNumber !== null));
            initFilters();
        }
    }, [originalList]);
    console.log(missingName);

    const noNameHeader = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold">ADP to CCURE Mismatch: These employees have a badge number in ADP but it doesnt match anything in CCURE.</span>
            <ExportAsExcel filteredData={missingName} dataFields={excelHeaders} fileName={fileName} />
        </div>
    );

    const initFilters = () => {
        setFilters({
            lastName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            firstName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            hireDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            title: { value: null, matchMode: FilterMatchMode.IN },
            department: { value: null, matchMode: FilterMatchMode.IN },
            badgeNumber: { value: null, matchMode: FilterMatchMode.IN },
        });
    };

    return (
        <div>
            <DataTable
                header={noNameHeader}
                scrollable
                size="small"
                scrollHeight="flex"
                style={{ fontSize: "18px" }}
                filters={filters}
                value={missingName}
                paginator
                filterDisplay="menu"
                className="p-datatable-customers"
                rows={10}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rowsPerPageOptions={[10, 20, 50]}
                sortField="department"
                sortOrder={1}
            >
                <Column sortable
                    filter
                    align="center"
                    alignHeader="center"
                    header="First Name"
                    field="firstName" />
                <Column sortable
                    filter
                    align="center"
                    alignHeader="center"
                    header="Last Name"
                    field="lastName" />
                <Column sortable
                    align="center"
                    alignHeader="center"
                    header="Department"
                    showFilterMatchModes={false}
                    field="department" />
                <Column sortable
                    align="center"
                    alignHeader="center"
                    header="Title"
                    showFilterMatchModes={false}
                    field="title" />
                <Column sortable
                    align="center"
                    alignHeader="center"
                    header="Badge Number"
                    showFilterMatchModes={false}
                    field="badgeNumber" />
            </DataTable>
        </div>
    );
};

export default MissingNameTable;