import React from "react";
import { Button } from "primereact/button";
import { saveAsExcelFile } from "./ExportFunctions";

const ExportAsExcel = ({ filteredData, dataFields, fileName }) => {

    const formatDate = (date)=> {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const day = String(date.getDate()).padStart(2, '0');
        
        return year + month + day;
    }

	const exportAsExcel = () => {

		let exportList = [...filteredData];
		let newList = [];
		for (let person of exportList) {
			newList.push(JSON.parse(JSON.stringify(person, dataFields)));
		}
		console.log(newList);
		import("xlsx").then((xlsx) => {
			const worksheet = xlsx.utils.json_to_sheet(newList);
			const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
			const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
			saveAsExcelFile(excelBuffer, fileName);
		});
	};

	return (
		<Button style={{ float: "right" }}
			label="Export Excel"
			aria-label="Export Excel"
			onClick={() => exportAsExcel()} />
	);
}

export default ExportAsExcel;