import React, { useEffect, useState, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import ExportExcelContacts from "./ExportComponents/ExportExcelContacts.js";
import ExportPdfContacts from "./ExportComponents/ExportPdfContacts.js";

const ContactTable = ({ employeeList, filters }) => {
	const [contactsDepartment, setContactsDepartment] = useState([]);
	const [filteredContactData, setFilteredContactData] = useState([]);

	useEffect(() => {
		setFilteredContactData(employeeList);
		filterFiltersContact(employeeList);
	}, [employeeList]);

	const filterFiltersContact = (filteredData) => {
		let filteredDepartments = [];
		filteredData.forEach((e) => {
			if (!filteredDepartments.includes(e.businessUnit) && e.businessUnit != null) {
				filteredDepartments.push(e.businessUnit);
			}
		});
		setContactsDepartment(filteredDepartments.sort((a, b) => a.localeCompare(b)));
	};

	const departmentRowFilterTemplateContacts = (options) => {
		return (
			<MultiSelect value={options.value}
				options={contactsDepartment}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				placeholder="Select Departments"
				className="p-column-filter"
				showClear />
		);
	};

	return (
		<div style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
			<div>
				<DataTable
					scrollable
					size="small"
					scrollHeight="flex"
					style={{ fontSize: "18px" }}
					filters={filters}
					globalFilterFields={["lastName", "firstName", "businessUnit", "workPhone", "workMobliePhone"]}
					value={employeeList}
					paginator
					filterDisplay="menu"
					className="p-datatable-customers"
					rows={10}
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
					rowsPerPageOptions={[10, 20, 50]}
					onValueChange={(contactData) => {
						setFilteredContactData(contactData);
						filterFiltersContact(contactData);
					}}
					sortField="businessUnit"
					sortOrder={1}
				>
					<Column sortable
						filter
						align="center"
						alignHeader="center"
						header="First Name"
						field="firstName" />
					<Column sortable
						filter
						align="center"
						alignHeader="center"
						header="Last Name"
						field="lastName" />
					<Column sortable
						filter
						align="center"
						alignHeader="center"
						header="Department"
						filterElement={departmentRowFilterTemplateContacts}
						field="businessUnit"
						showFilterMatchModes={false} />
					<Column sortable
						align="center"
						alignHeader="center"
						header="Landline"
						showFilterMatchModes={false}
						field="workPhone" />
					<Column sortable
						align="center"
						alignHeader="center"
						header="Mobile"
						showFilterMatchModes={false}
						field="workMobliePhone" />
				</DataTable>
			</div>
			<div>
				<ExportPdfContacts filteredContactData={filteredContactData} />
				<ExportExcelContacts exportList={filteredContactData} />
			</div>
		</div>
	);
};

export default ContactTable;