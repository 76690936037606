import React, { useEffect, useState, useRef, useContext } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { UserContext } from "../UserContext.js";
import { Dialog } from "primereact/dialog";
import ExportPdfEmployee from "./ExportComponents/ExportPdfEmployee.js";
import ContactTable from './ContactTable.js';
import BirthdayTable from "./BirthdayTable.js";
import { Checkbox } from "primereact/checkbox";

export const EmployeeTable = ({ employeeList, updateEmployeeList }) => {
	const user = useContext(UserContext);
	const [allEmployees, setAllEmployees] = useState(null);
	const [filters, setFilters] = useState(null);
	const [globalFilterValue, setGlobalFilterValue] = useState("");
	const [departments, setDepartments] = useState(null);
	const [titles, setTitles] = useState(null);
	const [selectVeterans, setSelectVeterans] = useState(false);
	const [tempEmployees, setTempEmployees] = useState([]);
	const [bdayModalOpen, setBdayModalOpen] = useState(false);
	const [dataUnsaved, setDataUnsaved] = useState(false);
	const [filteredData, setFilteredData] = useState([]);
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const toast = useRef(null);

	const css = `
    .p-datatable {
        .p-datatable-wrapper {
        width: 100%;
        }
    }
`;

	const beforeunload = (e) => {
		if (dataUnsaved) {
			e.preventDefault();
			e.returnValue = true;
		}
	};

	useEffect(() => {

		if (employeeList !== undefined && allEmployees === null) {
			let _departments = [];
			let _titles = [];

			let _uniqueDepartments = [...new Set(employeeList.filter(employee => employee?.businessUnit !== null)
				.map(employee => employee.businessUnit))];
			let _uniqueTitles = [...new Set(employeeList.filter(employee => employee.title != null)
				.map((employee) => employee.title))];

			_departments = [..._uniqueDepartments].sort();
			_titles = [..._uniqueTitles].sort();

			employeeList.sort(nameSort);
			setAllEmployees(employeeList);
			setTempEmployees(employeeList)
			setFilteredData(employeeList);
			setDepartments(_departments);
			setTitles(_titles);
		}

		initFilters();
	}, [employeeList]);

	const handleSelectVeteransChange = (e)=>{
		setSelectVeterans(e.checked)
		if(e.checked){
			setAllEmployees(prevData=>prevData.filter((emp)=>(emp.militaryStatusCode=="X" || emp.militaryStatusCode =="Y")))
		}
		else{
			setAllEmployees(tempEmployees)
		}
	}
	const nameSort = (a, b) => {
		if (a.lastName < b.lastName) {
			return -1;
		}
		if (a.lastName > b.lastName) {
			return 1;
		}
		return 0;
	};
	const imgBodyTemplate = (rowData) => {
		if (rowData.thumbnail || rowData?.rowData?.thumbnail) {
			return <img style={{ maxHeight: "170px" }} src={"data:image/png;base64," + (rowData.thumbnail || rowData?.rowData?.thumbnail)} />;
		}

		return null;
	};
	const formatDate = (value) => {
		const date = new Date(value);
		const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
		const day = String(date.getUTCDate()).padStart(2, '0');
		const year = String(date.getUTCFullYear()).slice(-2);

		return `${month}/${day}/${year}`;

	};

	const formatDateLong = (value) => {
		return value.toLocaleDateString("en-US", {
			day: "2-digit",
			month: "2-digit",
			year: "2-digit",
		});
	};
	const dateBodyTemplate = (rowData) => {
		return formatDate(new Date(rowData?.hireDate || rowData?.value));
	};
	const bdayBodyTemplate = (rowData) => {
		return rowData.birthDaySanitized;
	};
	const cellEditor = (options) => {
		return (
			<InputTextarea autoResize
				value={options.value}
				style={{ width: "100%" }}
				rows={5}
				cols={30}
				onChange={(e) => options.editorCallback(e.target.value)} />
		);
	};
	const submit = async (data) => {
		await user?.apiCall(
			"AddComment",
			{
				partitionKey: user?.user?.userId,
				rowKey: data.rowKey,
				comments: data.comments,
			},
			"POST"
		).then((res) => {
			if (res === 'Success') {
				const employeeIndex = allEmployees.findIndex(employee => employee.rowKey === data.rowKey);

				if (employeeIndex !== -1) {
					const updatedEmployees = [...allEmployees];
					
					updatedEmployees[employeeIndex] = {
						...updatedEmployees[employeeIndex],
						comments: data.comments
					};
					updateEmployeeList(updatedEmployees);
				}

				showSuccess();
				setDataUnsaved(false);
			}
		});
	};
	const renderHeader = () => {
		return (
			<div>
				<div style={{ lineHeight: "35px" }}>
					<ExportPdfEmployee exportList={filteredData} />
					<Button
						tooltip={"View current and upcoming birthdays"}
						style={{ marginLeft: "5px" }}
						type="button"
						icon="fa-solid fa-calendar-days"
						onClick={() => setBdayModalOpen(true)}
						className="p-button-info mr-2"
						data-pr-tooltip="View current and upcoming birthdays"
						tooltipOptions={{ position: "right", mouseTrack: true, mouseTrackLeft: 15 }}
					/>
					<Button
						tooltip={"View Contact List"}
						style={{ marginLeft: "5px" }}
						type="button"
						icon="fa-solid fa-phone"
						onClick={() => setContactModalOpen(true)}
						className="p-button-success mr-2"
						data-pr-tooltip="View Contact List"
						tooltipOptions={{ position: "right", mouseTrack: true, mouseTrackLeft: 15 }}
					/>
					<div style={{ display:'inline-flex', marginLeft: "30px", marginBottom:"2px", alignItems: "center",height:"40px" }}>
						<Checkbox
						inputId="selectVeterans"
						checked={selectVeterans}
						onChange={(e) => handleSelectVeteransChange(e)}
						className="mr-2"
						/>
						<label htmlFor="selectVeterans" style={{ fontSize: "14px" }}>
						Select All Veterans
						</label>
					</div>

					<div style={{ float: "right", marginBottom: "10px" }} className="flex justify-content-between">
						<Button type="button"
							icon="pi pi-filter-slash"
							label="Clear"
							onClick={clearFilter} />
						<span className="p-input-icon-left">
							<i className="pi pi-search" />
							<InputText style={{ marginLeft: "5px" }}
								value={globalFilterValue}
								onChange={onGlobalFilterChange}
								placeholder="Keyword Search" />
						</span>
					</div>
				</div>
			</div>
		);
	};

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };
		_filters["global"].value = value;

		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	const initFilters = () => {
		setFilters({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			employeeId: { value: null, matchMode: FilterMatchMode.CONTAINS },
			lastName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
			firstName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
			hireDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
			yearsOfService: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
			birthDaySanitized: { value: null, matchMode: FilterMatchMode.CONTAINS },
			title: { value: null, matchMode: FilterMatchMode.IN },
			businessUnit: { value: null, matchMode: FilterMatchMode.IN },
			comments: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
		});
		setGlobalFilterValue("");
	};
	const clearFilter = () => {
		initFilters();
	};

	const onRowEditComplete = (e) => {
		let _employees = [...allEmployees];
		let { newData, index } = e;
		var empIndex = _employees
			.map(function (e) {
				return e.rowKey;
			})
			.indexOf(newData.rowKey);
		_employees[empIndex] = newData;
		submit(newData);
		setAllEmployees(_employees);
		setTempEmployees(_employees)
	};
	const showSuccess = () => {
		toast.current.show({ severity: "success", summary: "Success", detail: "Commments Saved", life: 3000 });
	};

	const dateFilterTemplate = (options) => {
		return (
			<Calendar value={options.value}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				dateFormat="mm/dd/yy"
				placeholder="mm/dd/yyyy"
				mask="99/99/9999" />
		);
	};
	const departmentRowFilterTemplate = (options) => {
		return (
			<MultiSelect value={options.value}
				options={departments}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				placeholder="Select Departments"
				className="p-column-filter"
				showClear />
		);
	};

	const titleRowFilterTemplate = (options) => {
		return (
			<MultiSelect value={options.value}
				options={titles}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				placeholder="Select Titles"
				className="p-column-filter"
				showClear />
		);
	};

	const birthdayFilterTemplate = (options) => {
		const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

		return (
			<Dropdown style={{ width: "100px" }}
				value={options.value}
				options={MONTHS}
				onChange={(e) => options.filterCallback(e.value, options.index)}
				placeholder="Select a Month"
				className="p-column-filter" />
		);
	};
	const commentBodyTemplate = (rowData) => {
		return <span style={{ whiteSpace: "pre-line" }}>{rowData.comments}</span>;
	};

	const phoneBodyTemplate = (rowData) => {
		const { workPhone, workMobilePhone } = rowData;
		const out = [];

		if (workPhone != null) {
			out.push(
				<div key="workPhone" style={{ display: "block" }}>
					<b>L: </b>
					{workPhone}
				</div>
			);
		}

		if (workMobilePhone != null && workMobilePhone !== workPhone) {
			out.push(
				<div key="workMobilePhone" style={{ display: "block" }}>
					<b>M: </b>
					{workMobilePhone}
				</div>
			);
		}

		return <div>{out}</div>;
	};
	
	const firstNameBodyTemplate = (row) => {
		if(row.militaryStatusCode == "X" || row.militaryStatusCode == "Y")
		{
			return `(V) ${row.firstName}`
		}
		return row.firstName
	}

	const setFilterOptions = (filteredData) => {
		let filteredDepartments = [];
		let filteredTitles = [];
		filteredData.forEach((e) => {
			if (!filteredDepartments.includes(e.businessUnit) && e.businessUnit != null) {
				filteredDepartments.push(e.businessUnit);
			}
			if (!filteredTitles.includes(e.title) && e.title != null) {
				filteredTitles.push(e.title);
			}
		});
		setDepartments(filteredDepartments.sort((a, b) => a.localeCompare(b)));
		setTitles(filteredTitles.sort((a, b) => a.localeCompare(b)));
	};

	if (allEmployees) {
		return (
			<>
				<Toast ref={toast} />

				<div style={{ height: "calc(100vh - 60px)" }}>
					<Dialog header="Birthday List Export"
						visible={bdayModalOpen}
						style={{ width: "75vw" }}
						onHide={() => setBdayModalOpen(false)}>
						<BirthdayTable employeeList={allEmployees} filters={filters} />
					</Dialog>

					<Dialog header="Contact List"
						visible={contactModalOpen}
						style={{ width: "75vw" }}
						onHide={() => setContactModalOpen(false)}>
						<ContactTable employeeList={allEmployees} filters={filters} />
					</Dialog>

					<DataTable
						size="small"
						tableStyle={{ fontSize: "18px" }}
						onRowEditComplete={onRowEditComplete}
						editMode="row"
						header={renderHeader}
						filters={filters}
						globalFilterFields={["lastName", "firstName", "employeeBirthday", "jobTitle", "businessUnit", "title", "status", "comments"]}
						value={allEmployees}
						paginator
						filterDisplay="menu"
						rows={10}
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						rowsPerPageOptions={[10, 20, 50]}
						onValueChange={(filteredData) => {
							setFilteredData(filteredData);
							setFilterOptions(filteredData);
						}}
						sortField="lastName"
						sortOrder={1}
					>

						<Column align="center"
							style={{ minWidth: "170px" }}
							field="imageData"
							editor={(options) => imgBodyTemplate(options)}
							body={imgBodyTemplate} />
						<Column sortable
							filter
							align="center"
							alignHeader="center"
							header="First Name"
							field="firstName"
							body={firstNameBodyTemplate}
						/>
						<Column sortable
							filter
							align="center"
							alignHeader="center"
							header="Last Name"
							field="lastName" />
						<Column sortable
							filter
							align="center"
							alignHeader="center"
							header="Title"
							filterElement={titleRowFilterTemplate}
							field="title"
							showFilterMatchModes={false} />
						<Column sortable
							filter
							align="center"
							alignHeader="center"
							header="Department"
							filterElement={departmentRowFilterTemplate}
							field="businessUnit"
							showFilterMatchModes={false} />
						<Column sortable
							filter
							align="center"
							alignHeader="center"
							header="Hire Date"
							filterField="hireDate"
							field="hireDate"
							dataType="date"
							body={dateBodyTemplate}
							editor={(options) => dateBodyTemplate(options)}
							filterElement={dateFilterTemplate} />
						<Column sortable
							filter
							align="center"
							alignHeader="center"
							dataType="numeric"
							header="Years"
							field="yearsOfService" />
						<Column sortable
							filter
							align="center"
							alignHeader="center"
							header="Birthday"
							body={bdayBodyTemplate}
							filterElement={birthdayFilterTemplate}
							showFilterMatchModes={false}
							field="birthDaySanitized" />
						<Column sortable
							align="center"
							alignHeader="center"
							header="Phone"
							body={phoneBodyTemplate}
							showFilterMatchModes={false}
							field="phoneCombined" />
						<Column sortable
							filter
							header="Comments"
							body={commentBodyTemplate}
							style={{ minWidth: "25%" }}
							field="comments"
							editor={(options) => cellEditor(options)} />
						<Column rowEditor
							style={{ maxWidth: "1%", minWidth: "8rem" }}
							bodyStyle={{ textAlign: "center" }}>
						</Column>
					</DataTable>
					<style>{css}</style>
				</div>
			</>
		);
	}
	else {
		return <ProgressSpinner style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "50%" }} />;
	}
};
