import React from 'react';
import { Button } from "primereact/button";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

const ExportPdfEmployee = ({ exportList }) => {

	const formatDate = (value) => {
		return value.toLocaleDateString("en-US", {
			day: "2-digit",
			month: "2-digit",
			year: "2-digit",
		});
	};

	const getAnniversaryFlag = (hireDate) => {
		if (hireDate !== null) {
			const hireYear = new Date(hireDate).getFullYear();
			const currentYear = new Date().getFullYear();
			const yearsSinceHire = currentYear - hireYear;

			return yearsSinceHire;
		}
		return -1;
	};

	const cols = [
		{ field: "", header: "Portrait" },
		{ field: "lastName", header: "Last Name" },
		{ field: "firstName", header: "First Name" },
		{ field: "nickName", header: "Nickname" },
		{ field: "hireDate", header: "Hire Date" },
		{ field: "yearsOfService", header: "Years" },
		{ field: "birthDaySanitized", header: "Birthday" },
		{ field: "title", header: "Title" },
		{ field: "businessUnit", header: "Dept" },
		//{ field:'comments',  header:'Comments'}
	];
	const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

	const exportPdf = () => {
		const header = exportColumns.map((col) => col.title);
		const pdf = new jsPDF("l", "pt", "a4");

		exportList.sort((a, b) => {
			if (a.businessUnit < b.businessUnit) return -1;
			if (a.businessUnit > b.businessUnit) return 1;
			if (a.name < b.name) return -1;
			if (a.name > b.name) return 1;
			return 0;
		});

		// Group exportData by department
		const groupedData = {};
		exportList.forEach((rowData) => {
			const row = {...rowData}
			row.imageData = "data:application/pdf;base64," + row.thumbnail;
			row.hireDate = formatDate(new Date(row.hireDate));
			if((row.militaryStatusCode == "Y" || row.militaryStatusCode == "X") && !String(row.lastName).includes("(V)"))
			{
				row.lastName = `(V) ${row.lastName}`;
			}
			if (!groupedData[row.businessUnit]) {
				groupedData[row.businessUnit] = [];
			}
			groupedData[row.businessUnit].push(row);
		});

		let firstPage = true;

		Object.keys(groupedData).forEach((businessUnit) => {

			const departmentData = groupedData[businessUnit];

			if (!firstPage) {
				pdf.addPage();
			}
			firstPage = false;
			pdf.autoTable({
				head: [header],
				body: departmentData.map((row) => {
					return exportColumns.map((col) => row[col.dataKey])
				}),
				headStyles: { rowHeight: 25, valign: 'middle' },
				styles: {
					fontSize: 10,
					minCellHeight: 97,
				},
				columnStyles: {
					0: { cellWidth: 85 },
					1: { cellWidth: 100 },
					2: { cellWidth: 100 },
					3: { cellWidth: 75 },
					4: { cellWidth: 60 },
					5: { cellWidth: 36 },
					6: { cellWidth: 75 },
					8: { cellWidth: 85 },

				},
				bodyStyles: { valign: 'middle' },
				willDrawPage: (data) => {
					const legendX = 40;
					const legendY = pdf.internal.pageSize.height - 20 - 20;
					pdf.setFontSize(10);

					pdf.setFillColor(144, 238, 144);
					pdf.rect(legendX, legendY, 10, 10, 'F');
					pdf.text("New Hire", legendX + 15, legendY + 8);

					pdf.setFillColor(173, 216, 230);
					pdf.rect(legendX + 120, legendY, 10, 10, 'F');
					pdf.text("5th Anniversary", legendX + 135, legendY + 8);

					pdf.text("(V): Veteran", legendX + 260, legendY + 8);
				},
				willDrawCell: (data) => {
					if (data.section === "body") {
						const rowIndex = data.row.index;
						const rowData = departmentData[rowIndex];
						const hireDate = new Date(rowData.hireDate);
						var flag = getAnniversaryFlag(hireDate);
						if (flag === 0) {
							pdf.setFillColor(144, 238, 144);
						}
						if (flag === 5) {
							pdf.setFillColor(173, 216, 230);
						}
					}
				},
				didDrawCell: (data) => {
					if (data.section === "body" && data.column.index === 0) {
						const rowIndex = data.row.index;
						const base64Img = departmentData[rowIndex]?.imageData;
						if (base64Img) {
							const imgWidth = 77;
							const imgHeight = 95;
							pdf.addImage(base64Img, "JPEG", data.cell.x + 2, data.cell.y + 2, imgWidth, imgHeight);
						}
					}
				},
				rowPageBreak: 'avoid'
			});
		});

		pdf.save("rolodex.pdf");
	};

	return (
		<Button tooltip={"Export to PDF"}
			type="button"
			icon="fa-solid fa-file-pdf"
			onClick={exportPdf}
			className="p-button-warning mr-2"
			data-pr-tooltip="Export to PDF"
			tooltipOptions={{ position: "right", mouseTrack: true, mouseTrackLeft: 15 }} />
	);
}

export default ExportPdfEmployee;
