import React from "react";
import { Button } from "primereact/button";
import { saveAsExcelFile } from "./ExportFunctions";

// TODO: Update this component to use "ExportAsExcel" component

const ExportExcelBirthday = ({ filteredData }) => {

	const exportExcelBirthday = () => {

		let exportList = [...filteredData];
		let newList = [];
		for (let person of exportList) {
			newList.push(JSON.parse(JSON.stringify(person, ["firstName", "lastName", "businessUnit", "birthDaySanitized"])));
		}
		console.log(newList);
		import("xlsx").then((xlsx) => {
			const worksheet = xlsx.utils.json_to_sheet(newList);
			const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
			const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
			saveAsExcelFile(excelBuffer, "rolodexBirthdaysExport");
		});
	};

	return (
		<Button style={{ float: "right" }}
			label="Export Excel"
			aria-label="Export Excel"
			onClick={() => exportExcelBirthday()} />
	);
}

export default ExportExcelBirthday;