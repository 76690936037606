import React, { useEffect, useState, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatDate } from "../../Utilities/SharedUtilities";
import ExportAsExcel from "../ExportComponents/ExportAsExcel";
import { FilterMatchMode, FilterOperator } from "primereact/api";


const MissingBadgeNumberTable = ({ originalList }) => {
    const [missingPicture, setMissingPicture] = useState([]);
    const [filters, setFilters] = useState(null);

    useEffect(() => {
        if (originalList !== undefined && originalList !== null && originalList.length > 0) {
            setMissingPicture(originalList.filter(employee => employee.badgeNumber === null));
            initFilters();
        }
    }, [originalList]);

    console.log(missingPicture);
    const initFilters = () => {
        setFilters({
            employeeId: { value: null, matchMode: FilterMatchMode.CONTAINS },
            lastName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            firstName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            hireDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            title: { value: null, matchMode: FilterMatchMode.IN },
            department: { value: null, matchMode: FilterMatchMode.IN },
        });
    }


    const formatDate = (value) => {
		const date = new Date(value);
		const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
		const day = String(date.getUTCDate()).padStart(2, '0');
		const year = String(date.getUTCFullYear()).slice(-2);

		return `${month}/${day}/${year}`;

	};
    const dateBodyTemplate = (rowData) => {
        return formatDate(new Date(rowData?.hireDate || rowData?.value));
    };
    var excelHeaders = ["firstName", "lastName", "department", "title", "hireDate"];
    var fileName = "MissingName";

    const noPictureHeader = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold">Badge Number Blank in ADP: These employees do not have badge numbers in ADP. </span>
            <ExportAsExcel filteredData={missingPicture} dataFields={excelHeaders} fileName={fileName} />
        </div>
    );

    return (
        <div>
            <DataTable
                header={noPictureHeader}
                scrollable
                size="small"
                scrollHeight="flex"
                style={{ fontSize: "18px" }}
                filters={filters}
                globalFilterFields={["lastName", "firstName", "department", "workPhone", "workMobliePhone"]}
                value={missingPicture}
                paginator
                filterDisplay="menu"
                className="p-datatable-customers"
                rows={10}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rowsPerPageOptions={[10, 20, 50]}
                sortField="department"
                sortOrder={1}
            >
                <Column sortable
                    filter
                    align="center"
                    alignHeader="center"
                    header="First Name"
                    field="firstName" />
                <Column sortable
                    filter
                    align="center"
                    alignHeader="center"
                    header="Last Name"
                    field="lastName" />
                <Column sortable
                    align="center"
                    alignHeader="center"
                    header="Department"
                    showFilterMatchModes={false}
                    field="department" />
                <Column sortable
                    align="center"
                    alignHeader="center"
                    header="Title"
                    showFilterMatchModes={false}
                    field="title" />
                <Column sortable
                    filter
                    align="center"
                    alignHeader="center"
                    header="Hire Date"
                    filterField="hireDate"
                    field="hireDate"
                    dataType="date"
                    body={dateBodyTemplate}
                    editor={(options) => dateBodyTemplate(options)}
                />
            </DataTable>
        </div>
    );
};

export default MissingBadgeNumberTable;