import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useMsal } from "@azure/msal-react";

const AppTopbar = (props) => {
	const { instance, accounts } = useMsal();
	const activeAccount = instance.getActiveAccount();
	
	const navigate = useNavigate();

	const goDashboard = () => {
		navigate('/');
	};
	const goLogout = () => {

		const logoutRequest = {
			account: instance.getAccountByHomeId(activeAccount.homeAccountId),
		};
		instance.logoutRedirect(logoutRequest);
	
	};
	const topbarItemsClassName = classNames('topbar-items fadeInDown', { 'topbar-items-visible': props.topbarMenuActive });
	const menuButtonClassName = classNames('p-link', { 'menu-button-rotate': props.rotateMenuButton });
	const profileItemClassName = classNames('profile-item', { 'active-top-menu': props.activeTopbarItem === 'profile' });
	return (
		<div className="topbar clearfix">
			<div className="topbar-left" >
				<button style={{ float: 'left' }}
					className="p-link"
					onClick={goDashboard}>
					<img alt="Logo"
						src="assets/layout/images/logo.png"
						className="topbar-logo" />
				</button>
				<div style={{ fontSize: '1.75rem', color: '#FFF', fontWeight: 'lighter', paddingLeft: '2.8rem', marginTop: '-2px' }}>ACAA Rolodex</div>
			</div>

			<div className="topbar-right" >
				<button className={menuButtonClassName} id="menu-button" onClick={props.onMenuButtonClick} >
					<i className="pi pi-angle-left"></i>
				</button>

				<button className="p-link" id="topbar-menu-button" onClick={props.onTopbarMenuButtonClick}>
					<i className="pi pi-bars"></i>
				</button>
				<ul className={topbarItemsClassName}>
					{
						(props.profileMode === 'top' || props.horizontal) && (
							<>							<li className={profileItemClassName} onClick={(e) => props.onTopbarItemClick({ originalEvent: e, item: 'profile' })}>
								<button className="p-link" onClick={goLogout}>
									<span className="topbar-item-name">Logout</span>
								</button>

							</li>
								<li className={profileItemClassName} >
									<button className="p-link">
										<a className="topbar-item-name" href="https://stacaaintegrations.blob.core.windows.net/user-guides/RolodexUserGuide.pdf" download="RolodexUserGuide.pdf" target="_blank">
											Help
										</a>
									</button>
								</li>
							</>

						)
					}

				</ul>
			</div>
		</div>
	);
}

export default AppTopbar;
