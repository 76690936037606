import React from "react";
import { Button } from "primereact/button";

const ExportPdfBirthday = ({ filteredData }) => {
	const exportColumnsBirthday = [
		{ title: "Portrait", dataKey: "" },
		{ title: "First Name", dataKey: "firstName" },
		{ title: "Last Name", dataKey: "lastName" },
		{ title: "Department", dataKey: "businessUnit" },
		{ title: "Birthday", dataKey: "birthDaySanitized" },
	];

	const formatDate = (value) => {
		return value.toLocaleDateString("en-US", {
			day: "2-digit",
			month: "2-digit",
			year: "2-digit",
		});
	};

	const exportPdfBirthday = () => {
		filteredData.forEach((emp) => {
			emp.imageData = "data:application/pdf;base64," + emp.thumbnail;
			emp.hireDate = formatDate(new Date(emp.hireDate));
		});
		import("jspdf").then((jsPDF) => {
			import("jspdf-autotable").then(() => {
				const doc = new jsPDF.default("p", "pt", "a4");
				let i = 0;
				doc.autoTable(exportColumnsBirthday, filteredData, {
					didDrawCell: (data) => {
						if (data.section === "body" && data.column.index === 0) {
							var base64Img = filteredData[i++]?.imageData;
							if (base64Img) {
								doc.addImage(base64Img, "JPEG", data.cell.x + 2, data.cell.y + 2, 76, 95);
							}
						}
					},
					styles: { fontSize: 10, minCellHeight: 100 },
					columnStyles: { 0: { cellWidth: 76 } },
					rowPageBreak: "avoid",
				});
				doc.save("rolodexBirthdaysExport.pdf");
			});
		});
	};

	return (
		<Button label="Export PDF"
			aria-label="Export PDF"
			onClick={() => exportPdfBirthday()} />
	);
}

export default ExportPdfBirthday;